var main = require('integrations/main');
var reserve = require('./product/reserve');

main.baseFiles.menu = require('./components/menu');
main.baseFiles.modal = require('./components/modal').init;
main.baseFiles.test = require('./components/discounts');
main.baseFiles.pdpInstoreInventory = require('./product/pdpInstoreInventory');
main.baseFiles.phoneNumbers = require('./components/phoneNumbers');
main.baseFiles.shipping = require('./checkout/shipping');
main.baseFiles.newsletterSignup = require('./newsletterSignup/newsletterSignup');
main.baseFiles.randomizeCarousel = require('./components/randomizeCarousel').init;
main.baseFiles.digitalGuidesMenu = require('./components/digitalGuidesMenu').init;

require('./thirdParty/JsBarcode.all.min');
main.baseFiles.reserve = require('./product/reserve').init;
module.exports = main;
