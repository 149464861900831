'use strict';

var baseShipping = require('base/checkout/shipping');

function updateShippingAddressFormValues(shipping) {
    var addressObject = $.extend({}, shipping.shippingAddress);

    if (!addressObject) {
        addressObject = {
            firstName: null,
            lastName: null,
            address1: null,
            address2: null,
            city: null,
            postalCode: null,
            stateCode: null,
            countryCode: null,
            phone: null
        };
    }

    addressObject.isGift = shipping.isGift;
    addressObject.giftMessage = shipping.giftMessage;

    $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
        var form = el.form;
        if (!form) return;
        var countryCode = addressObject.countryCode;

        $('input[name$=_firstName]', form).val(addressObject.firstName ? addressObject.firstName : $('input[name$=_firstName]', form).val());
        $('input[name$=_lastName]', form).val(addressObject.lastName ? addressObject.lastName : $('input[name$=_lastName]', form).val());
        $('input[name$=_address1]', form).val(addressObject.address1 ? addressObject.address1 : $('input[name$=_address1]', form).val());
        $('input[name$=_address2]', form).val(addressObject.address2 ? addressObject.address2 : $('input[name$=_address2]', form).val());
        $('input[name$=_city]', form).val(addressObject.city ? addressObject.city : $('input[name$=_city]', form).val());
        $('input[name$=_postalCode]', form).val(addressObject.postalCode ? addressObject.postalCode : $('input[name$=_postalCode]', form).val());
        $('select[name$=_stateCode],input[name$=_stateCode]', form)
            .val(addressObject.stateCode ? addressObject.stateCode : $('select[name$=_stateCode],input[name$=_stateCode]', form).val());

        if (countryCode && typeof countryCode === 'object') {
            $('select[name$=_country]', form).val(addressObject.countryCode.value ? addressObject.countryCode.value : $('select[name$=_country]', form).val());
        }

        $('input[name$=_phone]', form).val(addressObject.phone ? addressObject.phone : $('input[name$=_phone]', form).val());

        $('textarea[name$=_giftMessage]', form).val(addressObject.giftMessage ? addressObject.giftMessage : $('textarea[name$=_giftMessage]', form).val());
    });

    $('body').trigger('shipping:updateShippingAddressFormValues', { shipping: shipping });
}

baseShipping.selectSingleShipping =  function () {
    $('body').on('shipping:selectSingleShipping', function () {
        $('.single-shipping .shipping-address, .shipping-summary .single-shipping').removeClass('d-none');
        $('.shipping-summary .multi-shipping').addClass('d-none');
    });
};

baseShipping.selectMultiShipping =  function () {
    $('body').on('shipping:selectMultiShipping', function (e, data) {
        $('.multi-shipping .shipping-address, .single-shipping .shipping-address, .shipping-summary .single-shipping').addClass('d-none');
        $('.shipping-summary .multi-shipping').removeClass('d-none');
    });
};

baseShipping.selectSingleShipAddress = function () {
    $('.single-shipping .addressSelector').on('change', function () {
        var form = $(this).parents('form')[0];
        var selectedOption = $('option:selected', this);
        var attrs = selectedOption.data();
        var shipmentUUID = selectedOption[0].value;
        var originalUUID = $('input[name=shipmentUUID]', form).val();
        var element;
        Object.keys(attrs).forEach(function (attr) {
            element = attr === 'countryCode' ? 'country' : attr;
            if (element === 'country' && shipmentUUID === 'new') {
                // don't clear out the country dropdown
            } else {
                $('[name$=' + element + ']', form).val(attrs[attr]);
            }
        });
        $('[name$=stateCode]', form).trigger('change');
        $('.shippingZipCode', form).trigger('change');
        if (shipmentUUID === 'new') {
            $(form).attr('data-address-mode', 'new');
            $(form).find('.shipping-address-block').removeClass('d-none');
        } else if (shipmentUUID === originalUUID) {
            $(form).attr('data-address-mode', 'shipment');
        } else if (shipmentUUID.indexOf('ab_') === 0) {
            $(form).attr('data-address-mode', 'customer');
        } else {
            $(form).attr('data-address-mode', 'edit');
        }
    });
};

baseShipping.methods.updateShippingAddressFormValues = updateShippingAddressFormValues;

module.exports = baseShipping;
