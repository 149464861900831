'use strict';

const emailSignup = () => {
    $(function() {
        $('#headerSubscribe').on('click', function(e) {
            e.preventDefault();
            $('input[type="text"].headersignup').val('');
        });
    });
}

exports.init = function () {
    emailSignup();
};
