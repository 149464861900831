'use strict';

const changeDiscount = () => {
    $(document).on("change", "#program-select", function () {
        let activeTab = $("#program-select").val();
        $(".tab").removeClass("activeTab");
        if (activeTab === 'non') {
            $("#banner-program").show();
            $(".default.tab").addClass("activeTab");
        } else {
            $("#program-select .program-lab").attr('disabled', 'disabled');
            $("#banner-program").hide();
            $("#" + activeTab).addClass("activeTab");
        }
    });
};

const printMail = () => {
    $(document).on("click", ".print-mail", function () {
        if ($(this).is(":checked")) {
            $(this).parents(".form-program").find(".printed-needed").removeClass("hidden");
            $(this).parents(".form-program").find(".printed-cards").prop("required", true);
        } else {
            $(this).parents(".form-program").find(".printed-needed").addClass("hidden");
            $(this).parents(".form-program").find(".printed-cards").prop("required", false);
        }
    });
};

const programStatus = () => {
    $(document).on("click", ".program-status", function () {
        if ($(this).hasClass("renew-status")) {
            $(this).siblings(".renewing-extra").removeClass("hidden");
        } else {
            $(this).siblings(".renewing-extra").addClass("hidden");
        }
    });
};

const distributionProgram = () => {
    $(".distribution").click(function () {
        $("#distribution-block").removeClass("hidden");
        $(".intro-date").removeClass("hidden");
    });
};

const discountProgram = () => {
    const changeHandler = () => {
        $("#distribution-block").empty();
        var discount_card = $("#discount-cards").val();
        var z = Number(discount_card);
        var mindate = "+21d";
        var distributionOffer =  $("input[name='distributionOffer']:checked");

        if (distributionOffer.length && distributionOffer.val().indexOf("Email my offers") >= 0) {
            mindate = "+3d";
        }
        var input_datepicker;
        var label_datepicker;

        switch (true) {
            case (z >=25 && z<=100):
                for (let i=1; i<3; i++) {
                    input_datepicker = "<input type='text' class='datepicker' autocomplete='off' name='Datepicker" +i+"'/> ";
                    label_datepicker = "<p><b>WEEK "+ i +" - Please select the FRIDAY of your team's desired 20% off event weekend.*</b></p>";
                    $("#distribution-block").append(label_datepicker).append(input_datepicker);
                    $("#distribution-block .datepicker").eq(i-1).datepicker({
                        beforeShowDay: function (date) {
                            return [date.getDay() == 5];
                        },
                        minDate: mindate
                    });
                    $("input[name='Datepicker1']").prop("required", true);
                }
                break;

            case (z >=125 && z<=200):
                for (let i=1; i<4; i++) {
                    input_datepicker = "<input type='text' class='datepicker' autocomplete='off' name='Datepicker" +i+"'/> ";
                    label_datepicker = "<p><b>WEEK "+ i +" - Please select the FRIDAY of your team's desired 20% off event weekend.*</b></p>";
                    $("#distribution-block").append(label_datepicker).append(input_datepicker);
                    $("#distribution-block .datepicker").eq(i-1).datepicker({
                        beforeShowDay: function (date) {
                            return [date.getDay() == 5];
                        },
                        minDate: mindate
                    });
                    $("input[name='Datepicker1']").prop("required", true);
                }
                break;

            case (z >=225 && z<=400):
                for (let i=1; i<5; i++) {
                    input_datepicker = "<input type='text' class='datepicker' autocomplete='off' name='Datepicker" +i+"'/> ";
                    label_datepicker = "<p><b>WEEK "+ i +" - Please select the FRIDAY of your team's desired 20% off event weekend.*</b></p>";
                    $("#distribution-block").append(label_datepicker).append(input_datepicker);
                    $("#distribution-block .datepicker").eq(i-1).datepicker({
                        beforeShowDay: function (date) {
                            return [date.getDay() == 5];
                        },
                        minDate: mindate
                    });
                    $("input[name='Datepicker1']").prop("required", true);
                }
                break;

            case (z > 400):
                for (let i=1; i<7; i++) {
                    input_datepicker = "<input type='text' class='datepicker' autocomplete='off' name='Datepicker" +i+"'/> ";
                    label_datepicker = "<p><b>WEEK "+ i +" - Please select the FRIDAY of your team's desired 20% off event weekend.*</b></p>";
                    $("#distribution-block").append(label_datepicker).append(input_datepicker);
                    $("#distribution-block .datepicker").eq(i-1).datepicker({
                        beforeShowDay: function (date) {
                            return [date.getDay() == 5];
                        },
                        minDate: mindate
                    });
                    $("input[name='Datepicker1']").prop("required", true);
                }
                break;
        }
    };

    $("#discount-cards").change(changeHandler);
    //$("input[name='distributionOffer']").click(changeHandler);
};

const otherEvents = () => {
    $(".phone_nr").blur(function () {
        if (validatePhone(this)) {
            $(this).next("p").html("Valid");
            $(this).next("p").css("color", "green");
            $(this).css("border", "1px solid green");
        } else {
            $(this).next("p").html("Invalid");
            $(this).next("p").css("color", "red");
            $(this).css("border", "1px solid red");
        }
    });

    $("#team-btn").click(function () {
        return validatePhone($(this).parents("form").find(".phone_nr")[0]);
    });

    $("#programs-btn").click(function () {
        if (validatePhone($(this).parents("form").find(".phone_nr")[0]) &  $("div.checkbox-group.required :checkbox:checked").length > 0) { //NOSONAR
            return true;
        } else {
            $("#required-status").removeClass("hidden");
            return false;
        }
    });
};

const validatePhone = (element) => {
    let filter = /^[(]\d{3}[)]\s\d{3}[-]\d{4}/,
        inputValue = $(element).val();

    if (filter.test(inputValue)) {
        return true;
    } else {
        $(".phone_nr").next("p").html("Invalid");
        $(".phone_nr").next("p").css("color", "red");
        $(element).css("border", "1px solid red");
        return false;
    }
};
const formatPhone = () => {
    $(document).on("input", ".phone_nr", function () {
        let number = $(this).val().replace(/[^\d]/g, "");
        if (number.length >= 6) {
            number = number.replace(/(\d{3})(\d{3})/, "($1) $2-");
        } else if (number.length >= 3) {
            number = number.replace(/(\d{3})/, "($1) ");
        }
        $(this).val(number);
    });
};

exports.init = function () {
    changeDiscount();
    printMail();
    programStatus();
    distributionProgram();
    discountProgram();
    otherEvents();
    formatPhone();
};
