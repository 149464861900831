'use strict';

var exports = $.extend({}, require('core/storeLocator/storeLocator'));
var toastHelpers = require('core/components/toast');

exports.selectStore = function () {
    $('.store-locator-container').on('click', '.select-store', (function (e) {
        e.preventDefault();
        var selectedStore = $(':checked', '.results-card .results');
        var data = {
            storeName: selectedStore.siblings('label').find('.store-name').text(),
            storeID: selectedStore.val(),
            searchRadius: $('#radius').val(),
            searchPostalCode: $('.results').data('search-key').postalCode,
            storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
            event: e
        };

        $('body').trigger('store:selected', data);
    }));
};

exports.setAsPreferredStore = function () {
    $('body').on('click', '.set-as-your-store', function (e) {
        e.preventDefault();
        var $this = $(this);
        var url = $this.data('action-url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (!data.error) {
                    const yourStoreText = $this.data('your-store-text');
                    const setStoreText = $this.data('set-store-text');
                    var $oldStore = $('.is-your-store');
                    if ($oldStore.length > 0) {
                        $oldStore.first().toggleClass('set-as-your-store is-your-store').text(setStoreText);
                    }
                    $this.toggleClass('set-as-your-store is-your-store').text(yourStoreText);
                    var $myStoreHeader = $('header .my-store');
                    const headerLinkUrl = $myStoreHeader.first().data('store-page-url');
                    $myStoreHeader.each( function() {
                        $(this).attr('href', headerLinkUrl + '?storeID=' + data.store.id).children('.my-store--name').first().text(data.store.name);
                    });
                    toastHelpers.methods.show('success', data.message, false);
                } else {
                    toastHelpers.methods.show('warning', data.message, false);
                }
            },
            error: function (data) {
                if (data.message) {
                    toastHelpers.methods.show('danger', data.message, false);
                }
            },
            complete: function (data) {
                $('.minicart').trigger('count:update', data.responseJSON);
            }
        });
    });
};

exports.requiredFields = function () {
    $("form[name='storelocator']").submit(function (e) {
        $(this).children('.no-zipcode').hide();
        if ($('#store-postal-code').val() === '' && $('#store-dropdown').val() === '') {
            e.preventDefault();
            $(this).children('.no-zipcode').show();
        }
    })
}


module.exports = exports;
