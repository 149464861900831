'use strict';

const disableDigitalGuidesClick = () => {
    $(function() {
        $('.nav-link#digitalGuides').on('click', function(e) {
            e.preventDefault();
            return false;
        });
    });
}

exports.init = function () {
    disableDigitalGuidesClick();
};
