var exports = $.extend({}, require('core/checkout/shipping'));
var base = require('core/checkout/shipping');
var addressHelpers = require('core/checkout/address')

/**
 * Hide and show to appropriate elements to show the multi ship shipment cards in the enter view
 * @param {jQuery} element - The shipping content
 */
 function enterMultishipView(element) {
    element.find('.btn-enter-multi-ship').removeClass('d-none');

    element.find('.view-address-block').addClass('d-none');
    element.find('.shipping-address').addClass('d-none');
    element.find('.btn-save-multi-ship.save-shipment').addClass('d-none');
    element.find('.btn-edit-multi-ship').addClass('d-none');
    element.find('.multi-ship-address-actions').addClass('d-none');
}

/**
 * Hide and show to appropriate elements to show the multi ship shipment cards in the view mode
 * @param {jQuery} element - The shipping content
 */
function viewMultishipAddress(element) {
    element.find('.view-address-block').removeClass('d-none');
    element.find('.btn-edit-multi-ship').removeClass('d-none');

    element.find('.shipping-address').addClass('d-none');
    element.find('.btn-save-multi-ship.save-shipment').addClass('d-none');
    element.find('.btn-enter-multi-ship').addClass('d-none');
    element.find('.multi-ship-address-actions').addClass('d-none');
}

/**
 * Hide and show to appropriate elements that allows the user to edit multi ship address information
 * @param {jQuery} element - The shipping content
 */
 function editMultiShipAddress(element) {
    // Show
    element.find('.shipping-address').removeClass('d-none');
    element.find('.shipping-address-block').show();
    element.find('.btn-save-multi-ship.save-shipment').removeClass('d-none');

    // Hide
    element.find('.view-address-block').addClass('d-none');
    element.find('.btn-enter-multi-ship').addClass('d-none');
    element.find('.btn-edit-multi-ship').addClass('d-none');
    element.find('.multi-ship-address-actions').addClass('d-none');

    $('body').trigger('shipping:editMultiShipAddress', { element: element, form: element.find('.shipping-form') });
}

/**
 * perform the proper actions once a user has clicked enter address or edit address for a shipment
 * @param {jQuery} element - The shipping content
 * @param {string} mode - the address mode
 */
 function editOrEnterMultiShipInfo(element, mode) {
    var form = $(element).closest('form');
    var root = $(element).closest('.shipping-content');

    $('body').trigger('shipping:updateDataAddressMode', { form: form, mode: mode });

    editMultiShipAddress(root);

    var addressInfo = addressHelpers.methods.getAddressFieldsFromUI(form);

    var savedState = {
        UUID: $('input[name=shipmentUUID]', form).val(),
        shippingAddress: addressInfo
    };

    root.data('saved-state', JSON.stringify(savedState));
}


exports.toggleMultiship = function () {
        var usingMultiShip = $('.multi-shipping-checkbox-block').data('multiship');
        $('.multi-shipping-checkbox-block').hide();

        $('input[name="usingMultiShipping"]').on('change', function () {
            $.spinner().start();
            var url = $('.multi-shipping-checkbox-block form').attr('action');
            var usingMultiShip = this.checked;
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                data: { usingMultiShip: usingMultiShip },
                success: function (response) {
                    if (response.error) {
                        window.location.href = response.redirectUrl;
                    } else {
                        $('body').trigger('checkout:updateCheckoutView', {
                            order: response.order,
                            customer: response.customer
                        });

                        if ($('#checkout-main').data('customer-type') === 'guest') {
                            if (exports.methods) {
                                if(exports.methods.clearShippingForms) {
                                    exports.methods.clearShippingForms(response.order);
                                 }
                            } else {
                                clearShippingForms(response.order);
                            }
                        } else {
                            response.order.shipping.forEach(function (shipping) {
                                $('input[value=' + shipping.UUID + ']').each(function (formIndex, el) {
                                    var form = el.form;
                                    if (!form) return;

                                    $(form).attr('data-address-mode', 'edit');
                                    var addressSelectorDropDown = $(form).find('.addressSelector option[value="ab_' + shipping.matchingAddressId + '"]');
                                    $(addressSelectorDropDown).prop('selected', true);
                                    $('input[name$=_isGift]', form).prop('checked', false);
                                    $('textarea[name$=_giftMessage]', form).val('');
                                    $(form).find('.gift-message').addClass('d-none');
                                });
                            });
                        }

                        if (usingMultiShip) {
                            $('body').trigger('shipping:selectMultiShipping', { data: response });
                        } else {
                            $('body').trigger('shipping:selectSingleShipping', { data: response });
                        }
                    }

                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        });
        if(usingMultiShip) {
            $('input[name="usingMultiShipping"]').trigger('change');
        }
    };

    exports.editMultiShipInfo = function () {
        $('.btn-edit-multi-ship').on('click', function (e) {
            e.preventDefault();
            editOrEnterMultiShipInfo($(this), 'edit');
        });
    };

    exports.selectMultiShipping = function () {
        $('body').on('shipping:selectMultiShipping', function (e, data) {
            $('.multi-ship .single-shipping .shipping-content, .multi-shipping .shipping-address').addClass('d-none');

            data.data.order.shipping.forEach(function (shipping) {
                var element = $('.multi-shipping .card[data-shipment-uuid="' + shipping.UUID + '"]');

                if (shipping.shippingAddress) {
                    if (exports.methods && exports.methods.viewMultishipAddress) {
                        exports.methods.viewMultishipAddress($(element));
                    } else {
                        viewMultishipAddress($(element));
                    }
                } else {
                    /* eslint-disable no-lonely-if */
                    if (exports.methods && exports.methods.enterMultishipView) {
                        exports.methods.enterMultishipView($(element));
                    } else {
                        enterMultishipView($(element));
                    }
                    /* eslint-enable no-lonely-if */
                }
            });
        });
    };

    exports.handleRegisteredUsers = function () {
        if ($('.multi-shipping-checkbox-block').data('multiship') && $('#registered-customer-js').data('address1') && $('#registered-customer-js').data('iscustomer')) {
            var interval = window.setInterval(function () {
                var $btn = $('.multi-shipping .card-body:first .btn-edit-multi-ship');
                if ($btn && $btn.length > 0 && $btn.is(":visible")) {
                    var $firstShipping = $('.multi-shipping .card-body:first')
                    base.methods.updateShippingMethodList($firstShipping.find('.shipping-form'));
                    $('.multi-shipping .card-body:first .btn-edit-multi-ship').trigger('click');
                    $('.multi-shipping .card-body:first .shipping-method-block')[0].scrollIntoView();
                    clearInterval(interval);
                }
            }, 150);
        } else if ($('#registered-customer-js').data('address1') && $('#registered-customer-js').data('iscustomer')) {
            base.methods.updateShippingMethodList($('.single-shipping .shipping-form'));
        }
    }

    exports.shippingMethodListener = function () {
        var ranRecently = {
            didRun: false
        }

        function triggerUpdateOnAddressComplete (ele) {
            var $shippingForm = $(ele).closest('.shipping-form');
            var postValue = $shippingForm.find('.shippingZipCode').val();
            var stateCode = $shippingForm.find('.shippingState').val();
            if (postValue && postValue !== '' && stateCode && stateCode !== '' && !ranRecently.didRun) {
                base.methods.updateShippingMethodList($shippingForm);
                ranRecently.didRun = true;
                window.setTimeout(() => {
                    ranRecently.didRun = false;
                }, 600)
                return;
            }
        }

        $(document).on('change', '.shipping-address input, .shipping-address select', function(e) {
            triggerUpdateOnAddressComplete(this);
        })

        $('.shipping-form').each(function () {
            if ($(this).is(":visible")) {
                triggerUpdateOnAddressComplete($(this).find('.shippingZipCode'));
            }
        });
    }

    exports.makeSureAddressIsSelected = function () {
        $(window).on('load', function () {
            window.setTimeout(function () {
                var $billingAddressSelector = $('#billingAddressSelector');
                if ($billingAddressSelector.length > 0 && $billingAddressSelector.val() !== 'manual-entry') {
                    $('#billingAddressSelector option').each(function () {
                        var val = $(this).val()
                        if (val && val !== 'manual-entry') {
                            $(this).prop('selected', true);
                            $(".address-selector-block .addressSelector").trigger('change');
                            return
                        }
                    });
                }
            }, 300);
        });
    }

    module.exports = exports;
