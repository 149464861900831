
'use strict';

function check(e, next) {

    let captcha = $('[name=g-recaptcha-response]');

    if (next.data) {
        var formData = next.data.split('&');

        for(var x=0; x<formData.length; x++) {
            var key = formData[x].split('=')[0];

            if (key == "dwfrm_recaptcha_recaptchaToken") {
                formData[x] = "dwfrm_recaptcha_recaptchaToken=" + (captcha.length ? captcha[0].value : '');
            }
        }

        next.data = formData.join("&");
    }

    $.ajax(next);
}

module.exports = {
    check: check
}
