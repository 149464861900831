'use strict';

function randomizeCarousel($carousel) {
    let $slider = $carousel.find('.slider');
    let $elements = $slider.children('.slide.tns-item[id*=-item]');
    const elementId = $elements.first().attr('id').replace(/[0-9]+$/, '');
    const numSlides = $elements.length;
    let $prevElements = $elements.first().prevAll().length > 0 ? $elements.first().prevAll() : null;
    let $nextElements = $elements.last().nextAll().length > 0 ? $elements.last().nextAll() : null;

    let indexArray = Array.from(new Array(numSlides).keys()).sort( function() {
        return Math.random() - 0.5;
    });

    $elements.detach();

    for (let i = 0; i < numSlides; i++) {
        if ($nextElements) {
            $elements.eq(indexArray[i]).attr({
                'id': elementId+i,
                'data-position': i
            }).insertBefore($nextElements.first());
        } else {
            $slider.append(
                $elements.eq(indexArray[i]).attr({
                    'id': elementId+i,
                    'data-position': i
                })
            );
        }
    }

    $elements = $slider.children('.slide.tns-item[id*=-item]');

    if ($prevElements) {
        for (let i = 1; i <= $prevElements.length; i++) {
            $prevElements.eq(i-1).html($elements.eq(-i).html());
        }
    }

    if ($nextElements) {
        for (let i = 0; i <= $nextElements.length; i++) {
            $nextElements.eq(i).html($elements.eq(i).html());
        }
    }

    $slider.parents('.random-carousel').removeClass('invisible');
}

const randomizeCarousels = () => {
    $(function() {
        let $randomCarousels = $('.random-carousel');
        $randomCarousels.each( function() {
            randomizeCarousel($(this));
        });
    });
}

exports.init = function () {
    randomizeCarousels();
};
