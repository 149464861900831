'use strict';

var modal = require('core/components/modal');
var base = require('core/product/base');
const editReservelistProductModalSelector = '#editReservelistProductModal';

/**
 * shows reservelist toast message
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages"></div>');
    }

    $('.add-to-wishlist-messages').append('<div class="alert text-center add-to-wishlist-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

/**
 * update window reservelist data
 * @param {string} data - data returned from the server's ajax call
 */
function updateReservelistUncachedData(data, isToggle) {
    if (data.success && !isToggle) {
        var reserveListPIDs = new Array();
        for(var i = 0; i < data.list.items.length; i++){
            reserveListPIDs.push(data.list.items[i].pid);
        }
        var reservelistPIDs = new Set(reserveListPIDs);
    }

    else {
        var reservelistPIDs = new Set(window.UncachedData.reservelistPIDs);
        if (data.add) {
            reservelistPIDs.add(data.reservelistpid);
        } else {
            reservelistPIDs.delete(data.reservelistpid);
        }
    }

    window.UncachedData.reservelistPIDs = Array.from(reservelistPIDs);
}

/**
 * update clicked links attributes and text
 * @param {Object} specificLinks - jQuery object representing selection of reservelist links
 */
function updateReservelistLinkData(specificLinks, newProductId) {
    if (!window || !window.UncachedData || !window.UncachedData.reservelistPIDs) {
        return;
    }

    var reservelistLinks = specificLinks || $('a.reservelist');
    if (!reservelistLinks || !reservelistLinks.length) {
        return;
    }

    var reservelistPIDs = new Set(window.UncachedData.reservelistPIDs);

    reservelistLinks.each((_i, reservelistLink) => {
        var $reservelistLink = $(reservelistLink);
        var reservelistpid;
        if(newProductId){
            reservelistpid = newProductId;
        } else{
            reservelistpid = $reservelistLink.attr('data-reservelistpid');
        }
        var reservebtn = $('.reservelist-icon');
        if (reservelistpid) {
            var isInReservelist = reservelistPIDs.has(reservelistpid);

            $reservelistLink.attr('data-inreservelist', isInReservelist);
            if (isInReservelist) {
                $reservelistLink.attr('title', $reservelistLink.attr('data-removemessage'));
                reservebtn.html($reservelistLink.attr('data-removemessage'));
            } else {
                $reservelistLink.attr('title', $reservelistLink.attr('data-addmessage'));
                reservebtn.html($reservelistLink.attr('data-addmessage'));
            }

            var icon = $reservelistLink.find('.reservelist-icon');
            if (icon && icon.length) {
                $(icon).toggleClass('selected', isInReservelist);
            }
        }
    });
}


function initReservelistData(newProductId) {
    var $xhr = [null, null];
    var url = $('a.reservelist').attr('data-getlisturl');
    if(url){
        if($xhr[0] && $xhr[0].abort){
            $xhr[0].abort(); //< abort a previous identical ajax call if it's still running
        }
        $xhr[0] = $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            data: {
            },
            success: function (data) {
                updateReservelistUncachedData(data, false);
                updateReservelistLinkData();
            }
        });

        var storeId = $('.my-store').data('store-id');
        var getAtsUrl = $('a.reservelist').attr('data-getatsactionurl');
        var isDropShipROPIS = $('a.reservelist').data('isropisdropship');
        var isInStoreROPIS = $('a.reservelist').data('isropisinstore');
        var isDcROPIS = $('a.reservelist').data('isropisdc');
        var pid;
        if (newProductId){
            pid = newProductId;
        }
        else{
            pid = $('span.product-id').text().trim();
        }
        var requestData = {
            pid: pid,
            storeId: storeId
        };
        if($xhr[1] && $xhr[1].abort){
            $xhr[1].abort();  //< abort a previous identical ajax call if it's still running
        }

        $('.availability-container').remove(); //< ROPIS items don't use the regular availability.isml container
        if(isInStoreROPIS){
            $xhr[1] = $.ajax({
                url: getAtsUrl,
                data: requestData,
                method: 'GET',
                success: function (response) {
                    updateReservelistLinkData(null, newProductId);
                    if(newProductId){
                        var $reservelink = $('.reservecontainer a.reservelist.btn-primary');
                        $reservelink.attr('data-reservelistpid', newProductId);
                    }
                    if (response.product && !response.product.readyToOrder) {
                        //disable reserve button
                        var $reserveButton = $('.reservecontainer');
                        if($reserveButton.length > 0){
                            var $nearbyStoresCTA = $('.in-store-availability .selected-store-with-inventory .card-footer button');
                            var reserveButtonHTML = $reserveButton.html();
                            $reserveButton.html(reserveButtonHTML.replace('<div class=\"col\"', '<div class=\"col\" disabled'));
                            $nearbyStoresCTA.hide();
                        }
                    }
                    else if(response.product && response.product.readyToOrder){
                        //display and enable reserve button
                        var $reserveButton = $('.reservecontainer');
                        if($reserveButton.length > 0){
                            var $nearbyStoresCTA = $('.in-store-availability .selected-store-with-inventory .card-footer button');
                            var reserveButtonHTML = $reserveButton.html();
                            $reserveButton.html(reserveButtonHTML.replace('<div class=\"col\" disabled', '<div class=\"col\"'));
                            $nearbyStoresCTA.show();
                        }
                    }
                }
            });
        }
        else if (isDropShipROPIS) {
            // drop ship items inventory is checked using default inventory, so this will be hidden/shown based on pdpInstoreInventory.isml

            if(newProductId){
                var $reservelink = $('.reservecontainer a.reservelist.btn-primary');
                $reservelink.attr('data-reservelistpid', newProductId);
            }

            var dropshipOutOfStock = $('.dropship.notavailable').length > 0;
            if(dropshipOutOfStock){
                var $reserveButton = $('.reservecontainer');
                        if($reserveButton.length > 0){
                            var $nearbyStoresCTA = $('.in-store-availability .selected-store-with-inventory .card-footer button');
                            var reserveButtonHTML = $reserveButton.html();
                            $reserveButton.html(reserveButtonHTML.replace('<div class=\"col\"', '<div class=\"col\" disabled'));
                            $nearbyStoresCTA.hide();
                        }
            }
        }

        else if (isDcROPIS) {
            // DC ROPIS is not supported at the moment, can easily base off previous functionality -- Matt Davis
        }
    }
}

$('body').on('product:afterAttributeSelect', (e, data) => {
    var selectedProduct = data.data.product;
    if(selectedProduct && selectedProduct.id){
        initReservelistData(selectedProduct.id);
    }
});

/**
 * update clicked links attributes and text
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} $targetElement - the element that needs caused the need for a modal change
 */
function updateReservelistCartStatus(data, $targetElement) {
    if (data.success && data.add && $targetElement.hasClass('move')) {
        var itemToMove = {
            actionUrl: $targetElement.data('nextaction'),
            productID: $targetElement.data('reservelistpid'),
            productName: $targetElement.data('productname'),
            uuid: $targetElement.data('uuid')
        };

        // listener prefills #removeProductModal
        $('body').trigger('afterRemoveFromCart', itemToMove);

        setTimeout(function () {
            $('#removeProductModal').modal();
        }, 1000);
    }
}

/**
 * update display of items after a cart update (edit item in modal on cart show)
 */
function cartUpdate() {
    $('body').on('cart:update', function(e, data, uuid) {
        var updatedProduct = $('a.reservelist[data-uuid=' + uuid + ']');
        if (updatedProduct.length) {
            //TODO RVW is this good enough or do I need to update reservelist icon name/messaging etc...
            updatedProduct.attr('data-reservelistpid', data.newProductId);
            updateReservelistLinkData();
        }
    });
}

/**
 * attach onclick for reservelist icon toggle functionality
 */
function toggleReservelist() {
    $('body').on('click', 'a.reservelist', function (e) {
        var clicked = $(this);
        e.preventDefault();
        var url = clicked.attr('href');
        var reservelistpid = clicked.attr('data-reservelistpid');

        //TODO RVW option products
        // var optionId = $(this).closest('.product-detail').find('.product-option').attr('data-option-id');
        // var optionVal = $(this).closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
        // optionId = optionId || null;
        // optionVal = optionVal || null;
        if (!url || !reservelistpid) {
            return;
        }

        var $this = $(this);

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                reservelistpid: reservelistpid,
                // optionId: optionId,
                // optionVal: optionVal
            },
            success: function (data) {
                displayMessage(data);
                updateReservelistUncachedData(data, true);
                updateReservelistLinkData();
                updateReservelistCartStatus(data, $this);
                $('.minicart').trigger('count:update', data);
            },
            error: function (err) {
                displayMessage(err);
            }
        });
    });
}


/**
 * make link button work on reservelist social icons to create a sharing url
 */
function copyReservelistLink() {
    $('body').on('click', '.wl-social .share-link', function () {
        var $temp = $('<input>');
        $('body').append($temp);
        $temp.val($('#wlShareUrl').val()).select();
        document.execCommand('copy');
        $temp.remove();
        $('.copy-link-message').removeClass('d-none');
        setTimeout(function () {
            $('.copy-link-message').addClass('d-none');
        }, 3000);
    });
}

/**
 * attach on quickview:ready listener so that icon is correct in quickview on load
 */
function updateQuickView(e) {
    $('body').on('quickview:ready', function() {
        initReservelistData();
        updateReservelistLinkData();
    });
}

/**
 * attach on click on reservelist-show edit button to launch modal
 */
function viewProductViaEdit() {
    $('body').on('click', '.edit-add-to-reservelist .edit', function (e) {
        e.preventDefault();

        var editProductUrl = $(this).attr('href');
        $(e.target).trigger('editreservelistproduct:show');
        modal.getModalHtmlElement('editReservelistProductModal', 'quick-view-dialog');
        fillModalElement(editProductUrl);
    });
}

/**
 * replaces the content in the modal window for product variation to be edited.
 * @param {string} editProductUrl - url to be used to retrieve a new product model
 */
//TODO RVW unified in some way with quickView.js fillModalElement function?
function fillModalElement(editProductUrl) {
    var $modal = $(editReservelistProductModalSelector);
    $modal.find('.modal-body').spinner().start();
    $.ajax({
        url: editProductUrl,
        method: 'GET',
        dataType: 'json',
        success: function (data) {
            var parsedHtml = modal.parseHtml(data.renderedTemplate);

            $modal.find('.modal-body').empty();
            $modal.find('.modal-body').html(parsedHtml.body);
            $modal.find('.modal-footer').html(parsedHtml.footer);
            $modal.find('.modal-header .close .sr-only').text(data.closeButtonText);
            $modal.find('.enter-message').text(data.enterDialogMessage);
            $modal.find(editReservelistProductModalSelector).modal('show');

            $('body').trigger('editreservelistproduct:ready', $(editReservelistProductModalSelector));

            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}


/**
 * handles opening reservelist modal
 */
function focusEditReservelistProductModal() {
    $('body').on('shown.bs.modal', editReservelistProductModalSelector, function () {
        $(editReservelistProductModalSelector).siblings().attr('aria-hidden', 'true');
        $(editReservelistProductModalSelector + ' .close').focus();
    });
}

/**
 * handles closing reservelist modal
 */
function onClosingEditReservelistProductModal() {
    $('body').on('hidden.bs.modal', editReservelistProductModalSelector, function () {
        $(editReservelistProductModalSelector).remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        $(editReservelistProductModalSelector).siblings().attr('aria-hidden', 'false');
    });
}

/**
 * handles tabbing on reservelist modal
 */
function trapEditReservelistProductModalFocus() {
    $('body').on('keydown', editReservelistProductModalSelector, function (e) {
        var focusParams = {
            event: e,
            containerSelector: editReservelistProductModalSelector,
            firstElementSelector: '.close',
            lastElementSelector: '.btn-update-reservelist-product',
            nextToLastElementSelector: '.select-size'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
}

/**
 * handles update button on click for edit reservelist modal from reservelist-show and others
 */
function updateReservelistItem() {
    $('body').on('click', '.btn-update-reservelist-product', function (e) {
        e.preventDefault();

        var updateButtonBlock = $(this).closest('.reservelist-item-update-button-block').find('.update-reservelist-url');
        var updateProductUrl = updateButtonBlock.val();
        var uuid = updateButtonBlock.data('uuid');

        var form = {
            uuid: uuid,
            pid: base.methods.getPidValue($(this))
        };

        $(editReservelistProductModalSelector).spinner().start();

        $.ajax({
            url: updateProductUrl,
            type: 'post',
            context: this,
            data: form,
            dataType: 'json',
            success: function () {
                $.spinner().start();
                $(editReservelistProductModalSelector).spinner().stop();
                $(editReservelistProductModalSelector).remove();
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');
                var pageNumber = $('.reservelistItemCardsData').data('page-number') - 1;
                renderNewPageOfItems(pageNumber, false, '.product-info .edit-add-to-reservelist .edit:first');
            },
            error: function () {
                var msg = $('.btn-update-reservelist-product').data('error-msg');

                $(editReservelistProductModalSelector).spinner().stop();
                $(editReservelistProductModalSelector).remove();
                $('.modal-backdrop').remove();
                $('body').removeClass('modal-open');

                if ($('.update-reservelist-messages').length === 0) {
                    $('body').append(
                        '<div class="update-reservelist-messages "></div>'
                    );
                }

                $('.update-reservelist-messages')
                    .append('<div class="update-reservelist-alert text-center alert-danger">' + msg + '</div>');

                setTimeout(function () {
                    $('.update-reservelist-messages').remove();
                }, 5000);
            }
        });
    });
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 * @param {string} focusElementSelector - selector of the element to focus on
 */
function renderNewPageOfItems(pageNumber, spinner, focusElementSelector) {
    var publicView = $('.reservelistItemCardsData').data('public-view');
    var listUUID = $('.reservelistItemCardsData').data('uuid');
    var url = $('.reservelistItemCardsData').data('href');
    if (spinner) {
        $.spinner().start();
    }
    var scrollPosition = document.documentElement.scrollTop;
    var newPageNumber = pageNumber;
    $.ajax({
        url: url,
        method: 'get',
        data: {
            pageNumber: ++newPageNumber,
            publicView: publicView,
            id: listUUID
        }
    }).done(function (data) {
        $('.reservelistItemCards').empty();
        $('body .reservelistItemCards').parent().html(data);

        if (focusElementSelector) {
            $(focusElementSelector).focus();
        } else {
            document.documentElement.scrollTop = scrollPosition;
        }
    }).fail(function () {
        $('.more-wl-items').remove();
    });
    $.spinner().stop();
}

/**
 * handles add to cart on click for add to cart button on reservelist-show and others
 */
function addToCartFromReservelist() {
    $('body').on('click', '.add-to-cart', function () {
        var pid;
        var addToCartUrl;
        var pidsQty;

        $('body').trigger('product:beforeAddToCart', this);

        pid = $(this).data('pid');
        addToCartUrl = $(this).data('url');
        pidsQty = parseInt($(this).closest('.product-info').find('.quantity').val(), 10);

        var form = {
            pid: pid,
            quantity: pidsQty
        };

        if ($(this).data('option')) {
            form.options = JSON.stringify($(this).data('option'));
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    base.miniCartReportingUrl(data.reportingURL, data.error);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}


/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    $('.minicart').trigger('count:update', response);
    var messageType = response.error ? 'alert-danger' : 'alert-success';

    if ($('.add-to-cart-messages').length === 0) {
        $('body').append(
            '<div class="add-to-cart-messages"></div>'
        );
    }

    $('.add-to-cart-messages').append(
        '<div class="alert ' + messageType + ' add-to-basket-alert text-center" role="alert">'
        + response.message
        + '</div>'
    );

    setTimeout(function () {
        $('.add-to-basket-alert').remove();
    }, 5000);
}


/**
 * attaches on click for checkbox to toggle public status for whole list on reserveslist show
 */
function toggleReservelistStatus() {
    $('#isPublicList').on('click', function () {
        var listID = $('#isPublicList').data('id');
        updatePublicStatus(listID, null, null);
    });
}

/**
 * attaches on click for checkbox to toggle public status for individual items on reservelist show
 */
function toggleReservelistItemStatus() {
    $('body').on('click', '.reservelist-item-checkbox', function () {
        var itemID = $(this).closest('.reservelist-hide').find('.custom-control-input').data('id');
        var el = $(this).siblings('input');
        var resetCheckBox = function () {
            return el.prop('checked')
                ? el.prop('checked', false)
                : el.prop('checked', true);
        };

        updatePublicStatus(null, itemID, resetCheckBox);
    });
}

/**
 * toggles the public / private status of the item or reservelist item
 * @param {string} listID - the order model
 * @param {string} itemID - the customer model
 * @param {Object} callback - function to run if the ajax call returns with an
 *                        error so that the checkbox can be reset to it's original state
 */
function updatePublicStatus(listID, itemID, callback) {
    var url = $('#isPublicList').data('url');
    $.spinner().start();
    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
            listID: listID,
            itemID: itemID
        },
        success: function (data) {
            if (callback && !data.success) { callback(); }
            displayMessage(data, null);
        },
        error: function (err) {
            if (callback) { callback(); }
            displayMessage(err);
        }
    });
}

/**
 * attaches on click for clicking the "x" from reservelist show or account show
 */
function removeFromReservelist() {
    $('body').on('click', '.remove-from-reservelist', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        var elMyAccount = $('.account-page').length;
        // If user is in my account page, call Reservelist-RemoveProductAccount() end point and replace html with response
        if (elMyAccount > 0) {
            $('.reservelist-account-card').spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'html',
                data: {},
                success: function (html) {
                    $('.reservelist-account-card>.order-card').remove();
                    $('.reservelist-account-card').append(html);
                    $('.reservelist-account-card').spinner().stop();
                },
                error: function () {
                    var $elToAppend = $('.reservelist-account-card');
                    $elToAppend.spinner().stop();
                    var msg = $elToAppend.data('error-msg');
                    displayMessage($elToAppend, msg);
                }
            });
        // else user is in reservelist landing page, call Reservelist-RemoveProduct() end point and re-render container page
        } else {
            $.spinner().start();
            $.ajax({
                url: url,
                type: 'get',
                dataType: 'json',
                data: {},
                success: function () {
                    var pageNumber = $('.reservelistItemCardsData').data('page-number') - 1;
                    renderNewPageOfItems(pageNumber, false);
                },
                error: function () {
                    $.spinner().stop();
                    var $elToAppendWL = $('.reservelistItemCards');
                    var msg = $elToAppendWL.data('error-msg');
                    displayMessage($elToAppendWL, msg);
                }
            });
        }
    });
}

function moreWLItems() {
    $('body').on('click', '.more-wl-items', function () {
        var pageNumber = $('.reservelistItemCardsData').data('page-number');
        renderNewPageOfItems(pageNumber, true);
    });
}

module.exports = {
    cartUpdate: cartUpdate,
    toggleReservelist: toggleReservelist,
    updateReservelistLinkData: updateReservelistLinkData,
    updateReservelistUncachedData: updateReservelistUncachedData,
    displayMessage: displayMessage,
    copyReservelistLink: copyReservelistLink,
    updateQuickView: updateQuickView,
    viewProductViaEdit: viewProductViaEdit,
    focusEditReservelistProductModal: focusEditReservelistProductModal,
    onClosingEditReservelistProductModal: onClosingEditReservelistProductModal,
    trapEditReservelistProductModalFocus: trapEditReservelistProductModalFocus,
    updateReservelistItem: updateReservelistItem,
    renderNewPageOfItems: renderNewPageOfItems,
    addToCartFromReservelist: addToCartFromReservelist,
    handlePostCartAdd: handlePostCartAdd,
    toggleReservelistStatus: toggleReservelistStatus,
    toggleReservelistItemStatus: toggleReservelistItemStatus,
    updatePublicStatus: updatePublicStatus,
    removeFromReservelist: removeFromReservelist,
    moreWLItems: moreWLItems,
    init: function() {
        cartUpdate();
        toggleReservelist();
        updateReservelistLinkData();
        copyReservelistLink();
        updateQuickView();
        viewProductViaEdit();
        focusEditReservelistProductModal();
        onClosingEditReservelistProductModal();
        trapEditReservelistProductModalFocus();
        updateReservelistItem();
        addToCartFromReservelist();
        toggleReservelistStatus();
        toggleReservelistItemStatus();
        removeFromReservelist();
        moreWLItems();
        initReservelistData();

        $('body').on('click', '.cancel-ropis-order', function (e) {
            e.preventDefault();
    
            var orderID = $(this).data('oid');

            var $ropisCancelConfirmBtn = $('.ropis-order-cancel-confirmation-btn');

            $ropisCancelConfirmBtn.data('oid', orderID);
        });

        $('body').on('click', '.ropis-order-cancel-confirmation-btn', function (e) {
            $.spinner().start();
            var url = $(this).data('action') + "?oid=" + $(this).data('oid');
            $.ajax({
                url: url,
                type: 'post',
                dataType: 'json',
                success: function (data) {
                    location.reload();
                },
                error: function (err) {
                    if (err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                }
            });
        });
    }
};

